<template>
  <EventCard
    description="Klik op de button om een nieuw evenement toe te voegen aan de App."
    featured
    :image="require('@/assets/img/events/meeting.webp')"
    name="Evenement toevoegen"
  />

  <EventCard
    v-for="event in eventList"
    :key="event.id"
    :description="event.description"
    :end-date-time="event.endDate"
    :event-id="event.id"
    :event-users="event.eventUsers"
    :image="event.visualImage"
    :name="event.title"
    :start-date-time="event.startDate"
  />

  <div class="mb-4" />

  <list-cutoff
    v-if="!showArchivedEvents"
    show-all-button-text="Toon gearchiveerde evenementen"
    @show-all-button-pressed="onShowArchiveButtonClick"
  />
</template>

<script>
import { getAll } from '@/api/providers/events';
import EventCard from '@/components/Events/Card.vue';
import ListCutoff from '@/components/UI/ListCutoff.vue';

import setRouteNameForModule from '@/helpers/route-name';

export default {
  name: 'EventsIndex',

  components: {
    EventCard,
    ListCutoff
  },

  data: () => ({
    events: [],
    showArchivedEvents: false
  }),

  computed: {
    eventList() {
      return this.showArchivedEvents
        ? this.events
        : this.currentEvents
    },

    currentEvents() {
      return this.events.filter(e => !e.isArchived);
    },
  },

  beforeMount() {
    setRouteNameForModule('Events', 'Overzicht');
  },

  mounted() {
    this.loadEvents();
  },

  methods: {
    loadEvents() {
      getAll()
        .then((events) => {
          this.events = events;
        })
        .catch((error) => error.default());
    },

    onShowArchiveButtonClick() {
      this.showArchivedEvents = true;
    }
  },
};
</script>
